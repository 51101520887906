import 'react-app-polyfill/ie11';

import React, { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';

import * as Sentry from '@sentry/react';
import { Loader } from 'semantic-ui-react';
import { BrowserTracing } from '@sentry/browser';

import createRootReducer from './reducers/index';
import { loadState, saveState } from './utils/persist';

import mySaga from './sagas/index.saga.js';
import { fetchMyProfile } from './utils/api/';

import App from './App';
import BrowserMessage from './components/BrowserMessage';
import ServiceMessage from './components/ServiceMessage';

import './assets/semantic/semantic.css';
import './index.css';

const AppWrapper = () => {
  // SET the local states
  const [store, setStore] = useState();
  const [config, setConfig] = useState();
  const [release, setRelease] = useState();
  const [loading, setLoading] = useState(true);
  const [apiDownPlaceholder, showApiDownPlaceholder] = useState(false);
  const [missingApiPlaceholder, showMissingApiPlaceholder] = useState(false);

  // GET config and release JSON files on mount
  useEffect(() => {
    fetch('./config.json', { cache: 'no-cache' })
      .then((response) => response.json())
      .then((data) => {
        setConfig(data);
        sessionStorage.setItem('config', JSON.stringify(data));
      });

    fetch('./release.json', { cache: 'no-cache' })
      .then((response) => response.json())
      .then((data) => {
        setRelease(data);
        sessionStorage.setItem('build', JSON.stringify(data));
      });
  }, []);

  // Start Sentry to detect and trace errors
  useEffect(() => {
    if (config) {
      if (config.api) {
        if (release) {
          // Once the config is ready, initialize the Redux store
          initStore();

          fetch(`${config.api}/api`)
            .then((response) => response.json())
            .then((data) => {
              if (process.env.NODE_ENV !== 'development') {
                Sentry.init({
                  dsn: 'https://adda419bf05745fdadb7f0a94c2b9011@sentry.io/1424887',
                  release: release.version,
                  environment: data.cluster,
                  integrations: [new BrowserTracing()],
                  tracesSampleRate: 1.0,
                });
              }
            })
            .catch(() => showApiDownPlaceholder(true));
        }
      } else {
        showMissingApiPlaceholder(true);
      }
    }
  }, [config, release]);

  // GET my profile data and
  // INITIALIZE the Redux store
  const initStore = () => {
    fetchMyProfile()
      .then((initData) => {
        if (initData) {
          // Create the Saga middleware
          const sagaMiddleware = createSagaMiddleware();

          // Compose middlewares
          let enhancer = compose(applyMiddleware(sagaMiddleware));

          // Compose with the developer tools only in development mode
          enhancer =
            process.env.NODE_ENV === 'production' ? enhancer : composeWithDevTools(enhancer);

          // Load the state from the session storage
          const persistedState = loadState(config.api);

          // Define the Redux initial state
          const initialState = { init: initData, ...persistedState };

          // Create the Redux store
          const store = createStore(createRootReducer, initialState, enhancer);

          // Save "init" and "login" data in the session storage when they change
          let currentInit;
          let currentLogin;
          store.subscribe(() => {
            let previousInit = currentInit;
            let previousLogin = currentLogin;

            currentInit = store.getState().init;
            currentLogin = store.getState().login;

            if (previousInit !== currentInit || previousLogin !== currentLogin) {
              saveState(
                {
                  init: store.getState().init,
                  login: store.getState().login,
                },
                config.api,
              );
            }
          });

          // Start the saga middleware
          sagaMiddleware.run(mySaga);

          setStore(store);
          setLoading(false);
        }

        return <ServiceMessage />;
      })
      .catch(() => showApiDownPlaceholder(true));
  };

  if (missingApiPlaceholder) {
    return (
      <div style={{ paddingTop: '50vh', textAlign: 'center' }}>
        Missing API field in config file
      </div>
    );
  } else if (apiDownPlaceholder) {
    return <ServiceMessage />;
  }

  // SHOW a spinner until data is ready
  if (loading) {
    return <Loader active style={{ color: 'var(--primaryColor)' }} />;
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <App config={config} release={release} />
      </BrowserRouter>
    </Provider>
  );
};

const domNode = document.getElementById('root');
const root = createRoot(domNode);

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ false || !!document.documentMode; // eslint-disable-line

// When an old browser is used, a message is shown and the application does not start
if (isIE) {
  root.render(<BrowserMessage />);
}

root.render(<AppWrapper />);

import React from 'react';
import { Segment, Image, Divider, Button } from 'semantic-ui-react';

import './style.css';

export default function ServiceMessage() {
  return (
    <div className="service-message">
      <Segment basic textAlign="center">
        <Image src="/images/logo-dark.svg" size="small" centered />

        <Divider hidden />

        <h4>Oops! Something went wrong</h4>
        <h5 style={{ color: 'grey' }}>
          Refresh to retry, if the problem persists
          <br />
          please contact our customer service
        </h5>

        <Button content="Refresh" icon="refresh" onClick={() => window.location.reload()} />
      </Segment>
    </div>
  );
}
